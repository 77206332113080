// Stili generici per tutta l'applicazione
@use "pages/general" as *;
@use "pages/errors" as *;
@use "pages/operations" as *;
@use "helpers/parameters.scss" as *;
@use "helpers/mixins.scss" as *;
@use "helpers/responsive.scss" as *;
// Stili per le select
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "../../../node_modules/ngx-guided-tour/css/guided-tour-base-theme.css";

.modal-open {
    overflow-y: hidden !important;
}
