// Messages
$disabled: #B9B9B9;
$success: #68ac35;
$success-hover: #4c8524;
$error: #e4002b;

//Status colors
$statusSuccess: #68AC35;
$statusError: #ED0131;
$statusWarning: #FFD573;
$statusWarningDark: #864022;
$statusDisabled: #595959;


// App palette oldPrimary (f74d00)
$primary-hover: linear-gradient(90deg, #56BA5B 0.01%, #88EA8B 99.99%, #88EA8B 100%);
$primary-gradient: linear-gradient(90deg, #56BA5B 0.01%, #88EA8B 99.99%, #88EA8B 100%);
$primary: #4baf50;
$secondary: #0A0A0A;
$SidenavBannerHeaderBtn: #0A0A0A;
$secondary-hover: #3A3A3A;
$white-hover: rgba(255, 255, 255, 0.3);
$black-hover: #727272;

//App colors
$lightGreen: #F8FEF3;

// Classi e costanti per gradient
$gradient1: linear-gradient(95.86deg, #F3F4F1 0%, #EDF3ED 99.82%);

.primary-gradient {
  background-size: 300% 100%;
  -moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  background-image: -moz-linear-gradient(to right, #4baf50, #88ea8b, #4baf50, #88ea8b);
  background-image: -webkit-linear-gradient(to right, #4baf50, #88ea8b, #4baf50, #88ea8b);
  background-image: linear-gradient(to right, #4baf50, #88ea8b, #4baf50, #88ea8b);
}

.primary-gradient-hover {
  background-position: 100% 0;
  -moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  background-image: -moz-linear-gradient(to right, #4baf50, #88ea8b, #4baf50, #88ea8b);
  background-image: -webkit-linear-gradient(to right, #4baf50, #88ea8b, #4baf50, #88ea8b);
  background-image: linear-gradient(to right, #4baf50, #88ea8b, #4baf50, #88ea8b);
}

$orange-for-upload: #58C621;

$accent: #f6b04e;
$accent-hover: #f8cc8e;
$blue-accent: #17238c;

$link: #0048D9;

// Grayscale
$black: #333333;
$gray1: #757575;
$gray2: #ababab;
$gray3: #dbdbdb;
$gray4: #E8E8E8;
$white: #ffffff;
$soft-gray: #f9f9f9;
$text-gray: #7e7e7e;

$page-content-bg: #f9fafc;

// Media Queries
$media-xxs: 480px;
$media-xs: 600px;
$media-sm: 960px;
$media-md: 1280px;
$media-lg: 1920px;

$tour-skip-link-color: $primary;
$tour-next-text-color: $white;
$tour-next-button-color: $primary;
$tour-next-button-hover: $primary-hover;
$tour-orb-color: $primary;
// $tour-skip-link-color : Skip button color.
// $tour-text-color : Color of the text that is in the tour step box.
// $tour-next-text-color : Next button text color.
// $tour-zindex : Base z-index for the tour.
// $tour-orb-color : Color of the orb to start a tour.
// $tour-next-button-color : Next button color.
// $tour-next-button-hover : Next button hover color.
// $tour-back-button-color : Back button color.
// $tour-shadow-color : Shadow backdrop that is used for the tour.
// $tour-step-color : Background color for the tour step box.