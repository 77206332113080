// Shadows
@mixin small-shadow {
  -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.04),
    0px 0px 1px 0px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.04),
    0px 0px 1px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
}
@mixin normal-shadow {
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04),
    0px 0px 1px 0px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04),
    0px 0px 1px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
}

@mixin medium-shadow {
  -webkit-box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04),
    0px 0px 1px 0px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04),
    0px 0px 1px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
}

@mixin big-shadow {
  -webkit-box-shadow: 0px 24px 32px 0px rgba(0, 0, 0, 0.04), 0px 16px 24px 0px rgba(0, 0, 0, 0.04),
    0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0px 24px 32px 0px rgba(0, 0, 0, 0.04), 0px 16px 24px 0px rgba(0, 0, 0, 0.04),
    0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
}

@mixin shadow-1 {
	box-shadow:0px 4px 8px rgba(0,0,0,0.040), 0px 0px 2px rgba(0,0,0,0.060), 0px 0px 1px rgba(0,0,0,0.040);
}
@mixin shadow-1 {
	box-shadow:0px 4px 8px rgba(0,0,0,0.040), 0px 0px 2px rgba(0,0,0,0.060), 0px 0px 1px rgba(0,0,0,0.040);
}
@mixin shadow-1 {
	box-shadow:0px 4px 8px rgba(0,0,0,0.040), 0px 0px 2px rgba(0,0,0,0.060), 0px 0px 1px rgba(0,0,0,0.040);
}
@mixin shadow-2 {
	box-shadow:0px 10px 20px rgba(0,0,0,0.040), 0px 2px 6px rgba(0,0,0,0.040), 0px 0px 1px rgba(0,0,0,0.040);
}
@mixin shadow-2 {
	box-shadow:0px 10px 20px rgba(0,0,0,0.040), 0px 2px 6px rgba(0,0,0,0.040), 0px 0px 1px rgba(0,0,0,0.040);
}
@mixin shadow-2 {
	box-shadow:0px 10px 20px rgba(0,0,0,0.040), 0px 2px 6px rgba(0,0,0,0.040), 0px 0px 1px rgba(0,0,0,0.040);
}
@mixin shadow-3 {
	box-shadow:0px 16px 24px rgba(0,0,0,0.060), 0px 2px 6px rgba(0,0,0,0.040), 0px 0px 1px rgba(0,0,0,0.040);
}
@mixin shadow-3 {
	box-shadow:0px 16px 24px rgba(0,0,0,0.060), 0px 2px 6px rgba(0,0,0,0.040), 0px 0px 1px rgba(0,0,0,0.040);
}
@mixin shadow-3 {
	box-shadow:0px 16px 24px rgba(0,0,0,0.060), 0px 2px 6px rgba(0,0,0,0.040), 0px 0px 1px rgba(0,0,0,0.040);
}
@mixin shadow-4 {
	box-shadow:0px 24px 32px rgba(0,0,0,0.040), 0px 16px 24px rgba(0,0,0,0.040), 0px 4px 8px rgba(0,0,0,0.040), 0px 0px 1px rgba(0,0,0,0.040);
}
@mixin shadow-4 {
	box-shadow:0px 24px 32px rgba(0,0,0,0.040), 0px 16px 24px rgba(0,0,0,0.040), 0px 4px 8px rgba(0,0,0,0.040), 0px 0px 1px rgba(0,0,0,0.040);
}
@mixin shadow-4 {
	box-shadow:0px 24px 32px rgba(0,0,0,0.040), 0px 16px 24px rgba(0,0,0,0.040), 0px 4px 8px rgba(0,0,0,0.040), 0px 0px 1px rgba(0,0,0,0.040);
}
@mixin shadow-4 {
	box-shadow:0px 24px 32px rgba(0,0,0,0.040), 0px 16px 24px rgba(0,0,0,0.040), 0px 4px 8px rgba(0,0,0,0.040), 0px 0px 1px rgba(0,0,0,0.040);
}

//Border radius
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}

@mixin border-top-left-radius($radius) {
  -webkit-border-top-left-radius: $radius;
  -moz-border-top-left-radius: $radius;
  -ms-border-top-left-radius: $radius;
  -o-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
}

@mixin border-top-right-radius($radius) {
  -webkit-border-top-right-radius: $radius;
  -moz-border-top-right-radius: $radius;
  -ms-border-top-right-radius: $radius;
  -o-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
}

@mixin border-bottom-right-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  -moz-border-bottom-right-radius: $radius;
  -ms-border-bottom-right-radius: $radius;
  -o-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
}

@mixin border-bottom-left-radius($radius) {
  -webkit-border-bottom-left-radius: $radius;
  -moz-border-bottom-left-radius: $radius;
  -ms-border-bottom-left-radius: $radius;
  -o-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
}

// Pulsanti
@mixin main-button-bg($bg) {
  background: $bg !important;
  &:hover {
    background: lighten($bg, 4%) !important;
    transition: all 0.3s ease;
  }
  &:active {
    background: lighten($bg, 8%) !important;
  }
}

@mixin layout-row-gap($gapValue) {
  // div, > p, > label, > svg-icon, > alloy-select, > alloy-button, > alloy-input, > alloy-upload, > alloy-datepicker, > alloy-check, > alloy-switch, > alloy-radio, > alloy-text-area
  & > * {
    margin-right: $gapValue;
    &:last-child {
      margin-right: inherit;
    }
  }
}

@mixin layout-column-gap($gapValue) {
  // div, > p, > label, > svg-icon, > alloy-select, > alloy-button, > alloy-input, > alloy-upload, > alloy-datepicker, > alloy-check, > alloy-switch, > alloy-radio, > alloy-text-area
  & > * {
    margin-bottom: $gapValue;
    &:last-child {
      margin-bottom: inherit;
    }
  }
}

@mixin fixed-size($width, $height) {
  height: $height;
  width: $width;
  max-height: $height;
  max-width: $width;
  min-height: $height;
  min-width: $width;
}

@mixin fixed-height($value) {
  height: $value;
  max-height: $value;
  min-height: $value;
}

@mixin fixed-width($value) {
  width: $value;
  max-width: $value;
  min-width: $value;
}

//Generatore di classi margin padding
@mixin margin-padding($attr, $attr-count: 10, $attr-steps: 10, $unit: "%") {
  $attr-list: null;
  @for $i from 0 through $attr-count {
    $attr-value: $attr-steps * $i;
    .#{$attr}#{$attr-value} {
      #{$attr}: #{$attr-value}#{$unit};
    }
    $attr-list: append($attr-list, unquote(".#{$attr}-#{$attr-value}"), comma);
  }
}


// FONT
@mixin state-label {
	font-size:0.625rem;
	font-family:"Open Sans";
	font-weight:600;
	font-style:normal;
	line-height:19px;
	text-decoration:none;
	text-transform:uppercase;
}
@mixin label-button {
	font-size:0.875rem;
	font-family:"Open Sans";
	font-weight:700;
	font-style:normal;
	line-height:17px;
	text-decoration:none;
	text-transform:uppercase;
    text-align: center;
}
@mixin light-h-1 {
	font-size:3.812rem;
	font-family:"Montserrat";
	font-weight:300;
	font-style:normal;
	line-height:69px;
	text-decoration:none;
	text-transform:none;
}
@mixin light-h-2 {
	font-size:3.062rem;
	font-family:"Montserrat";
	font-weight:300;
	font-style:normal;
	line-height:57px;
	text-decoration:none;
	text-transform:none;
}
@mixin light-h-3 {
	font-size:2.438rem;
	font-family:"Montserrat";
	font-weight:300;
	font-style:normal;
	line-height:47px;
	text-decoration:none;
	text-transform:none;
}
@mixin light-h-4 {
	font-size:1.938rem;
	font-family:"Montserrat";
	font-weight:300;
	font-style:normal;
	line-height:39px;
	text-decoration:none;
	text-transform:none;
}
@mixin light-h-5 {
	font-size:1.562rem;
	font-family:"Montserrat";
	font-weight:300;
	font-style:normal;
	line-height:33px;
	text-decoration:none;
	text-transform:none;
}
@mixin light-h-6 {
	font-size:1.188rem;
	font-family:"Open Sans";
	font-weight:300;
	font-style:normal;
	line-height:27px;
	text-decoration:none;
	text-transform:none;
}
@mixin light-p-default {
	font-size:0.938rem;
	font-family:"Open Sans";
	font-weight:300;
	font-style:normal;
	line-height:23px;
	text-decoration:none;
	text-transform:none;
}
@mixin light-p-medium {
	font-size:0.812rem;
	font-family:"Open Sans";
	font-weight:300;
	font-style:normal;
	line-height:21px;
	text-decoration:none;
	text-transform:none;
}
@mixin light-p-small {
	font-size:0.625rem;
	font-family:"Open Sans";
	font-weight:300;
	font-style:normal;
	line-height:18px;
	text-decoration:none;
	text-transform:none;
}
@mixin regular-h-1 {
	font-size:3.812rem;
	font-family:"Montserrat";
	font-weight:400;
	font-style:normal;
	line-height:69px;
	text-decoration:none;
	text-transform:none;
}
@mixin regular-h-2 {
	font-size:3.062rem;
	font-family:"Montserrat";
	font-weight:400;
	font-style:normal;
	line-height:57px;
	text-decoration:none;
	text-transform:none;
}
@mixin regular-h-3 {
	font-size:2.438rem;
	font-family:"Montserrat";
	font-weight:400;
	font-style:normal;
	line-height:47px;
	text-decoration:none;
	text-transform:none;
}
@mixin regular-h-4 {
	font-size:1.938rem;
	font-family:"Montserrat";
	font-weight:400;
	font-style:normal;
	line-height:39px;
	text-decoration:none;
	text-transform:none;
}
@mixin regular-h-5 {
	font-size:1.562rem;
	font-family:"Montserrat";
	font-weight:400;
	font-style:normal;
	line-height:33px;
	text-decoration:none;
	text-transform:none;
}
@mixin regular-h-6 {
	font-size:1.188rem;
	font-family:"Open Sans";
	font-weight:400;
	font-style:normal;
	line-height:27px;
	text-decoration:none;
	text-transform:none;
}
@mixin regular-p-default {
	font-size:0.938rem;
	font-family:"Open Sans";
	font-weight:400;
	font-style:normal;
	line-height:23px;
	text-decoration:none;
	text-transform:none;
}
@mixin regular-p-medium {
	font-size:0.812rem;
	font-family:"Open Sans";
	font-weight:400;
	font-style:normal;
	line-height:21px;
	text-decoration:none;
	text-transform:none;
}
@mixin regular-p-small {
	font-size:0.625rem;
	font-family:"Open Sans";
	font-weight:400;
	font-style:normal;
	line-height:18px;
	text-decoration:none;
	text-transform:none;
}
@mixin semi-bold-h-1 {
	font-size:3.812rem;
	font-family:"Montserrat";
	font-weight:600;
	font-style:normal;
	line-height:69px;
	text-decoration:none;
	text-transform:none;
}
@mixin semi-bold-h-2 {
	font-size:3.062rem;
	font-family:"Montserrat";
	font-weight:600;
	font-style:normal;
	line-height:57px;
	text-decoration:none;
	text-transform:none;
}
@mixin semi-bold-h-3 {
	font-size:2.438rem;
	font-family:"Montserrat";
	font-weight:600;
	font-style:normal;
	line-height:47px;
	text-decoration:none;
	text-transform:none;
}
@mixin semi-bold-h-4 {
	font-size:1.938rem;
	font-family:"Montserrat";
	font-weight:600;
	font-style:normal;
	line-height:39px;
	text-decoration:none;
	text-transform:none;
}
@mixin semi-bold-h-5 {
	font-size:1.562rem;
	font-family:"Montserrat";
	font-weight:600;
	font-style:normal;
	line-height:33px;
	text-decoration:none;
	text-transform:none;
}
@mixin semi-bold-h-6 {
	font-size:1.188rem;
	font-family:"Open Sans";
	font-weight:600;
	font-style:normal;
	line-height:27px;
	text-decoration:none;
	text-transform:none;
}
@mixin semi-bold-p-default {
	font-size:0.938rem;
	font-family:"Open Sans";
	font-weight:600;
	font-style:normal;
	line-height:23px;
	text-decoration:none;
	text-transform:none;
}
@mixin semi-bold-p-medium {
	font-size:0.812rem;
	font-family:"Open Sans";
	font-weight:600;
	font-style:normal;
	line-height:21px;
	text-decoration:none;
	text-transform:none;
}
@mixin semi-bold-p-small {
	font-size:0.625rem;
	font-family:"Open Sans";
	font-weight:600;
	font-style:normal;
	line-height:18px;
	text-decoration:none;
	text-transform:none;
}
@mixin bold-h-1 {
	font-size:3.812rem;
	font-family:"Montserrat";
	font-weight:700;
	font-style:normal;
	line-height:69px;
	text-decoration:none;
	text-transform:none;
}
@mixin bold-h-2 {
	font-size:3.062rem;
	font-family:"Montserrat";
	font-weight:700;
	font-style:normal;
	line-height:57px;
	text-decoration:none;
	text-transform:none;
}
@mixin bold-h-3 {
	font-size:2.438rem;
	font-family:"Montserrat";
	font-weight:700;
	font-style:normal;
	line-height:47px;
	text-decoration:none;
	text-transform:none;
}
@mixin bold-h-4 {
	font-size:1.938rem;
	font-family:"Montserrat";
	font-weight:700;
	font-style:normal;
	line-height:39px;
	text-decoration:none;
	text-transform:none;
}
@mixin bold-h-5 {
	font-size:1.562rem;
	font-family:"Montserrat";
	font-weight:700;
	font-style:normal;
	line-height:33px;
	text-decoration:none;
	text-transform:none;
}
@mixin bold-h-6 {
	font-size:1.188rem;
	font-family:"Open Sans";
	font-weight:700;
	font-style:normal;
	line-height:27px;
	text-decoration:none;
	text-transform:none;
}
@mixin bold-p-default {
	font-size:0.938rem;
	font-family:"Open Sans";
	font-weight:700;
	font-style:normal;
	line-height:23px;
	text-decoration:none;
	text-transform:none;
}
@mixin bold-p-medium {
	font-size:0.812rem;
	font-family:"Open Sans";
	font-weight:700;
	font-style:normal;
	line-height:21px;
	text-decoration:none;
	text-transform:none;
}
@mixin bold-p-small {
	font-size:0.625rem;
	font-family:"Open Sans";
	font-weight:700;
	font-style:normal;
	line-height:18px;
	text-decoration:none;
	text-transform:none;
}

