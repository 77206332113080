@import "../pages/general";
@import "../helpers/responsive.scss";
@import "../helpers/parameters.scss";
@import "../helpers/mixins.scss";

.page-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  padding: 24px;
  @include layout-column-gap(24px);

  .logo-container {
    @include fixed-size(220px, 85px);
  }

  span {
    @extend .title-page;
    color: $primary;
  }

  p {
    @extend .text-big;
    color: #8a8a8a;
    text-align: center;

    span {
      color: $black;

      &.small-font-weight {
        font-weight: 100;
      }
    }
  }

  .error-code-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .bold {
      font-weight: 700;
    }

    p,
    span {
      @extend .text-small;
      color: $gray2;
    }

    p {
      margin-top: 5px;
      background-color: #f5f5f5;
      padding: 2px 6px;
      @include border-radius(4px);
    }
  }
}

.page-login-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  max-width: 400px;
  width: 100%;
  @include layout-column-gap(24px);

  @include respond-to(xs) {
    padding: 24px;
  }
}